import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { XCircleIcon } from "@heroicons/react/20/solid";
import ActivityRestrictedSessions from "components/admin/ActivityRestrictedSessions";
import type { ActivityTicketFormData } from "components/admin/ActivityTicketsAddEditModal";
import ActivityTicketsAddEditModal from "components/admin/ActivityTicketsAddEditModal";
import SingleSessionTicketSales from "components/admin/SingleSessionTicketSales";
import SubscriptionPaymentScheduleModal from "components/admin/SubscriptionPaymentScheduleModal";
import TableWrapper from "components/admin/table/TableWrapper";
import { convertAmountBasedOnCurrency } from "helpers/currency";
import { getOverriddenTimeAsReadableString } from "helpers/date";
import {
  formatCurrency,
  renderTicketTypeFriendlyName,
  wait
} from "helpers/helpers";
import {
  getRegularPaymentScheduleDescription,
  getSubscriptionNameFromId
} from "helpers/subscription-plan";
import { getDoesTicketHaveSales } from "helpers/ticket";
import { kebabCase } from "lodash";
import IconInformationCircleSolid from "public/images/icons/information-circle_solid.svg";
import IconPlusCircle from "public/images/icons/plus-circle.svg";
import { useState } from "react";
import type { Activity, Ticket } from "types/model/activity";
import { TicketType } from "types/model/activity";
import type { ActivityGroupSalesData } from "types/model/activity-group";
import type { Client } from "types/model/client";
import type {
  PaymentSchedule,
  SubscriptionPlan
} from "types/model/subscription-plan";
import { PaymentScheduleType } from "types/model/subscription-plan";
import { cn } from "utils/cn";

const getOverriddenTimeDisplay = (startTime: string, endTime: string) => {
  if (!startTime || !endTime) {
    return null;
  }

  // Split start and end time strings on ":"
  const [startHours, startMinutes] = startTime.split(":");
  const [endHours, endMinutes] = endTime.split(":");

  return {
    start: {
      hours: parseInt(startHours, 10),
      minutes: parseInt(startMinutes, 10)
    },
    end: {
      hours: parseInt(endHours, 10),
      minutes: parseInt(endMinutes, 10)
    }
  };
};

interface ActivityTicketsProps extends UseFieldApiProps<Ticket[], HTMLElement> {
  isNewActivityGroup?: boolean;
  activityGroupSales?: ActivityGroupSalesData;
  activityGroupSalesIncludingCancelled?: ActivityGroupSalesData;
  activities?: Activity<string>[];
  subscriptionPlansData?: SubscriptionPlan[];
  client?: Client;
}

const ActivityTickets = (props: UseFieldApiConfig): React.ReactElement => {
  const {
    isNewActivityGroup,
    activityGroupSales,
    activityGroupSalesIncludingCancelled,
    activities,
    subscriptionPlansData,
    client,
    label,
    meta: { error, touched },
    input
  }: ActivityTicketsProps = useFieldApi(props);
  const [isDirty, setIsDirty] = useState(false);

  const [
    singleSessionTicketSalesTicketId,
    setSingleSessionTicketSalesTicketId
  ] = useState<string>(null);

  const [editingTicketId, setEditingTicketId] = useState<string>(null);
  const [isAddEditTicketModalOpen, setIsAddEditTicketModalOpen] =
    useState<boolean>(false);
  const [isPaymentScheduleModalOpen, setIsPaymentScheduleModalOpen] =
    useState(false);
  const [paymentScheduleToView, setPaymentScheduleToView] =
    useState<PaymentSchedule>(null);

  const handleViewPaymentSchedule = subscriptionPlanId => {
    const subscriptionPlan = subscriptionPlansData.find(
      plan => plan._id === subscriptionPlanId
    );
    setPaymentScheduleToView(subscriptionPlan.paymentSchedule);
    setIsPaymentScheduleModalOpen(true);
  };

  const handleClosePaymentScheduleModal = async () => {
    setIsPaymentScheduleModalOpen(false);
    await wait(300);
    setPaymentScheduleToView(null);
  };

  const { getState } = useFormApi();
  const { values } = getState();

  const onConfirmTicketAddEdit = (
    formData: ActivityTicketFormData,
    editingTicketId = null
  ): void => {
    if (editingTicketId) {
      const editingTicketIndex = input.value.findIndex(
        ticket => ticket._id === editingTicketId
      );

      const updatedTickets = [...input.value];

      updatedTickets[editingTicketIndex] = {
        ...updatedTickets[editingTicketIndex],
        name: formData.name,
        type: formData.type,
        price: convertAmountBasedOnCurrency(formData.price, client.currency),
        placeLimit:
          formData.placeLimit && formData.specifyTicketLimit
            ? parseInt(formData.placeLimit, 10)
            : null,
        ...(formData.type === TicketType.All && {
          shouldProRata: formData.shouldProRata
        }),
        ...(formData.type === TicketType.Single && {
          restrictSessions: formData.restrictSessions,
          sessionsCanBeUsedFor: formData.sessionsCanBeUsedFor,
          timeDisplay: formData.overrideSessionTimeDisplay
            ? getOverriddenTimeDisplay(
                formData.overrideStartTime,
                formData.overrideEndTime
              )
            : null
        }),
        ...(formData.type === TicketType.Subscription && {
          subscriptionPlan: subscriptionPlansData.find(
            subscription => subscription._id === formData.subscriptionPlanId
          ),
          allowMidMonthBookings: formData.allowMidMonthBookings,
          coverFeePerSession:
            formData.allowMidMonthBookings && formData.coverFeePerSession
              ? convertAmountBasedOnCurrency(
                  formData.coverFeePerSession,
                  client.currency
                )
              : null
        })
      };

      input.onChange(updatedTickets);
    } else {
      const newTicket: Ticket = {
        _id: `temp_${Math.random().toString(36).substring(7)}`,
        name: formData.name,
        type: formData.type,
        price: convertAmountBasedOnCurrency(formData.price, client.currency),
        enabled: true,
        placeLimit:
          formData.placeLimit && formData.specifyTicketLimit
            ? parseInt(formData.placeLimit, 10)
            : null,
        ...(formData.type === TicketType.All && {
          shouldProRata: formData.shouldProRata
        }),
        ...(formData.type === TicketType.Single && {
          restrictSessions: formData.restrictSessions,
          sessionsCanBeUsedFor: formData.sessionsCanBeUsedFor,
          timeDisplay: formData.overrideSessionTimeDisplay
            ? getOverriddenTimeDisplay(
                formData.overrideStartTime,
                formData.overrideEndTime
              )
            : null
        }),
        ...(formData.type === TicketType.Subscription && {
          subscriptionPlan: subscriptionPlansData.find(
            subscription => subscription._id === formData.subscriptionPlanId
          ),
          allowMidMonthBookings: formData.allowMidMonthBookings,
          coverFeePerSession:
            formData.allowMidMonthBookings && formData.coverFeePerSession
              ? convertAmountBasedOnCurrency(
                  formData.coverFeePerSession,
                  client.currency
                )
              : null
        })
      };

      const updatedTickets = [...input.value, newTicket];

      input.onChange(updatedTickets);
    }

    setEditingTicketId(null);
    setIsDirty(true);
  };

  const onEditTicket = (id: string): void => {
    setEditingTicketId(id);

    setIsAddEditTicketModalOpen(true);
  };

  const onCancelAddEditTicket = () => {
    setEditingTicketId(null);
  };

  const onUpdateTicketStatus = (id: string): void => {
    const ticketToUpdateStatusIndex = input.value.findIndex(
      ticket => ticket._id === id
    );

    const updatedTicketValues = [...input.value];

    updatedTicketValues[ticketToUpdateStatusIndex].enabled =
      !updatedTicketValues[ticketToUpdateStatusIndex].enabled;

    input.onChange(updatedTicketValues);
    setIsDirty(true);
  };

  const onDeleteTicket = (id: string): void => {
    const ticket = input.value.find(ticket => ticket._id === id);
    const doesTicketHaveSalesIncludingCancelled = getDoesTicketHaveSales(
      ticket,
      activityGroupSalesIncludingCancelled
    );

    if (doesTicketHaveSalesIncludingCancelled) {
      window.alert(
        "Sorry, this ticket cannot be deleted as there have already been bookings using it. Please disable it instead."
      );
      return;
    }

    const updatedTickets = input.value.filter(ticket => ticket._id !== id);

    input.onChange(updatedTickets);
    setIsDirty(true);
  };

  return (
    <div className="mt-5 border-t border-gray-200 pt-5">
      {isDirty && !isNewActivityGroup && (
        <div
          className="mb-5 rounded-md bg-blue-50 p-4"
          data-cy="msg-changes-on-save-activity-tickets"
        >
          <div className="flex">
            <div className="flex-shrink-0">
              <IconInformationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-blue-400"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Changes will be applied on save.
              </p>
            </div>
          </div>
        </div>
      )}

      {touched && error && (
        <>
          <div className="mb-5 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p
                  className="text-sm text-red-700"
                  data-cy={`error-${kebabCase(label)}`}
                >
                  {error}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {input.value.length > 0 && (
        <TableWrapper
          shadow={false}
          legend={
            input.value.some(
              ticket => ticket.type === TicketType.All && ticket.shouldProRata
            )
              ? "*Price calculated on a pro rata basis."
              : ""
          }
        >
          <table
            className="min-w-full divide-y divide-gray-200"
            data-cy="table-activity-tickets"
          >
            <thead>
              <tr>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Name
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Type
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Price
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Sales
                </th>
                <th className="bg-gray-50 px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {input.value.map((ticket, index) => (
                <tr className="bg-white" key={index}>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm font-medium text-gray-900",
                      !ticket.enabled && "text-opacity-50"
                    )}
                  >
                    {ticket.name}
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                      !ticket.enabled && "text-opacity-50"
                    )}
                  >
                    {renderTicketTypeFriendlyName(ticket.type)}
                    {ticket.restrictSessions && (
                      <ActivityRestrictedSessions
                        activitySessionIds={ticket.sessionsCanBeUsedFor}
                        allActivitySessions={values.datesInstances}
                        client={client}
                      />
                    )}
                    {ticket.placeLimit && (
                      <div
                        className={cn(
                          "text-xs text-gray-400",
                          !ticket.enabled && "text-opacity-50"
                        )}
                      >
                        Ticket limit: {ticket.placeLimit}
                      </div>
                    )}
                    {ticket.type === TicketType.Subscription &&
                      ticket.subscriptionPlan && (
                        <div
                          className={cn(
                            "text-xs text-gray-400",
                            !ticket.enabled && "text-opacity-50"
                          )}
                        >
                          {getSubscriptionNameFromId(
                            ticket.subscriptionPlan._id,
                            subscriptionPlansData
                          )}
                        </div>
                      )}
                    {ticket.type === TicketType.Subscription &&
                      !ticket.subscriptionPlan && (
                        <div className="text-xs text-red-600">
                          Select subscription plan for ticket
                        </div>
                      )}

                    {ticket.timeDisplay?.start && ticket.timeDisplay?.end && (
                      <div
                        className={cn(
                          "text-xs text-gray-400",
                          !ticket.enabled && "text-opacity-50"
                        )}
                      >
                        Time override:{" "}
                        {getOverriddenTimeAsReadableString(ticket.timeDisplay)}
                      </div>
                    )}
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                      !ticket.enabled && "text-opacity-50"
                    )}
                  >
                    {[TicketType.All, TicketType.Single].includes(
                      ticket.type
                    ) && (
                      <>
                        {formatCurrency({
                          rawAmount: ticket.price,
                          currency: client.currency
                        })}
                        {ticket.type === TicketType.All &&
                          ticket.shouldProRata &&
                          "*"}
                      </>
                    )}
                    {ticket.type === TicketType.Subscription &&
                      ticket.subscriptionPlan && (
                        <>
                          {ticket.subscriptionPlan.paymentSchedule.type ===
                          PaymentScheduleType.Fixed ? (
                            <a
                              className={cn(
                                "cursor-pointer font-medium text-indigo-600 hover:text-indigo-900 focus:underline focus:outline-none",
                                !ticket.enabled &&
                                  "pointer-events-none text-opacity-50"
                              )}
                              onClick={() =>
                                handleViewPaymentSchedule(
                                  ticket.subscriptionPlan._id
                                )
                              }
                            >
                              View details
                            </a>
                          ) : (
                            <>
                              {getRegularPaymentScheduleDescription(
                                ticket.subscriptionPlan.paymentSchedule,
                                client
                              )}
                            </>
                          )}
                          {ticket.coverFeePerSession !== null &&
                            ticket.coverFeePerSession !== undefined && (
                              <div
                                className={cn(
                                  "text-xs text-gray-400",
                                  !ticket.enabled && "text-opacity-50"
                                )}
                              >
                                Cover fee per session:{" "}
                                {formatCurrency({
                                  rawAmount: ticket.coverFeePerSession,
                                  currency: client.currency
                                })}
                              </div>
                            )}
                        </>
                      )}
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap px-6 py-4 text-sm text-gray-500",
                      !ticket.enabled && "text-opacity-50"
                    )}
                  >
                    {ticket.type === TicketType.All &&
                      (activityGroupSales?.allSessionTicketSales[ticket._id] ||
                        0)}
                    {ticket.type === TicketType.Subscription &&
                      (activityGroupSales?.subscriptionTicketSales[
                        ticket._id
                      ] ||
                        0)}
                    {ticket.type === TicketType.Single && (
                      <a
                        className={cn(
                          "cursor-pointer font-medium text-indigo-600 hover:text-indigo-900 focus:underline focus:outline-none"
                        )}
                        onClick={() =>
                          setSingleSessionTicketSalesTicketId(ticket._id)
                        }
                      >
                        View details
                      </a>
                    )}
                  </td>
                  <td
                    className={cn(
                      "whitespace-no-wrap cursor-pointer px-6 py-4 text-right text-sm font-medium",
                      !ticket.enabled && "text-opacity-50"
                    )}
                  >
                    <a
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() => onEditTicket(ticket._id)}
                    >
                      Edit
                    </a>
                    {" | "}
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() => onUpdateTicketStatus(ticket._id)}
                    >
                      {ticket.enabled ? "Disable" : "Enable"}
                    </a>
                    {" | "}
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-900"
                      onClick={() => onDeleteTicket(ticket._id)}
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      )}

      <div className={cn(input.value.length > 0 && "mt-4")}>
        <span className="inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={(): void => setIsAddEditTicketModalOpen(true)}
            data-cy="btn-add-ticket"
          >
            <IconPlusCircle
              width={24}
              height={24}
              className="-ml-1 mr-2 h-5 w-5"
            />
            Add new ticket
          </button>
        </span>
      </div>

      <ActivityTicketsAddEditModal
        isOpen={isAddEditTicketModalOpen}
        client={client}
        editingTicketId={editingTicketId}
        subscriptionPlansData={subscriptionPlansData}
        allTicketsData={input.value}
        setIsOpen={setIsAddEditTicketModalOpen}
        onCancel={onCancelAddEditTicket}
        onConfirmTicketAddEdit={onConfirmTicketAddEdit}
      />

      <SubscriptionPaymentScheduleModal
        paymentSchedule={paymentScheduleToView}
        client={client}
        isOpen={isPaymentScheduleModalOpen}
        setIsOpen={handleClosePaymentScheduleModal}
      />

      {activityGroupSales && singleSessionTicketSalesTicketId && (
        <SingleSessionTicketSales
          ticket={input.value.find(
            ticket => ticket._id === singleSessionTicketSalesTicketId
          )}
          activities={activities}
          salesData={
            activityGroupSales.singleSessionTicketSales[
              singleSessionTicketSalesTicketId
            ]
          }
          client={client}
          onClose={(): void => setSingleSessionTicketSalesTicketId(null)}
        />
      )}
    </div>
  );
};

export default ActivityTickets;
