import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { ReactNode } from "react";
import type { Children } from "types";
import { cn } from "utils/cn";

const alertVariants = cva("rounded-md p-4 flex items-start", {
  variants: {
    variant: {
      yellow: "bg-yellow-50",
      purple: "bg-purple-50",
      red: "bg-red-50",
      blue: "bg-blue-100"
    }
  }
});

const iconVariants = cva("h-5 w-5", {
  variants: {
    variant: {
      yellow: "text-yellow-400",
      purple: "text-purple-400",
      red: "text-red-400",
      blue: "text-blue-400"
    }
  }
});

const textVariants = cva("text-sm font-medium", {
  variants: {
    variant: {
      yellow: "text-yellow-700",
      purple: "text-purple-700",
      red: "text-red-700",
      blue: "text-blue-700"
    }
  }
});

type AlertVariants = VariantProps<typeof alertVariants>;

type Props = Children &
  AlertVariants & {
    className?: string;
    textClassName?: string;
    icon?: ReactNode;
  };

/**
 * An alert component that displays a message to the user with color variants.
 */
export const Alert = ({
  variant = "yellow",
  className,
  textClassName,
  icon,
  children
}: Props) => {
  return (
    <div className={cn(alertVariants({ variant }), className)}>
      <div className="flex-shrink-0">
        {icon || (
          <ExclamationCircleIcon
            aria-hidden="true"
            className={iconVariants({ variant })}
          />
        )}
      </div>
      <div className="ml-3">
        <p className={cn(textVariants({ variant }), textClassName)}>
          {children}
        </p>
      </div>
    </div>
  );
};
